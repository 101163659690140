<ng-container *mpHeaderElement="'left'">
  <mp-root-module-nav label="Kostenstellen" />
</ng-container>

<mp-overview moduleTitle="Kostenstellen" [resultCount]="costCenters().length" (search)="fetchCostCenters($event)">
  <button mat-menu-item routerLink="create">Neu</button>

  @if (costCentersLoaded()) {
    <cdk-virtual-scroll-viewport itemSize="100">
      <mp-overview-item
        *cdkVirtualFor="let costCenter of costCenters(); trackBy: trackByFn"
        [path]="costCenter.costCenterId"
      >
        <h2>{{ costCenter.name }}</h2>
        <span>{{ costCenter.number }}</span>
      </mp-overview-item>
    </cdk-virtual-scroll-viewport>
  } @else {
    <mp-spinner />
  }
</mp-overview>
