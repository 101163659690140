import { ScrollingModule } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, Component, Signal } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';

import { HeaderElementDirective } from '@core/shared/util';
import { OverviewModule, RootModuleNavComponent, SpinnerComponent } from '@core/ui';
import { CostCentersFacade } from '@mp/organization-master-data/cost-centers/data-access';
import { CostCenterListItem } from '@mp/organization-master-data/cost-centers/domain';

@Component({
  selector: 'mpomd-cost-centers-list',
  standalone: true,
  templateUrl: './cost-centers-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterLink,

    MatMenuModule,

    ScrollingModule,
    OverviewModule,
    SpinnerComponent,
    HeaderElementDirective,
    RootModuleNavComponent,
  ],
})
export class CostCentersListComponent {
  readonly costCenters: Signal<CostCenterListItem[]> = this.facade.costCenters;
  readonly costCentersLoaded: Signal<boolean> = this.facade.costCentersLoaded;

  constructor(private readonly facade: CostCentersFacade) {
    this.fetchCostCenters();
  }

  fetchCostCenters(searchTerm?: string): void {
    this.facade.fetchCostCenters(searchTerm);
  }

  trackByFn(_index: number, { costCenterId }: CostCenterListItem): string {
    return costCenterId;
  }
}
