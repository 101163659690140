import { permissions } from '@mpk/shared/domain';

/**
 * Permissions for the const center management
 */
enum CostCentersPermissionsEnum {
  None = 0,

  /**
   * Manage cost centers
   */
  ManageAllCostcenters = 1,
}

export const CostCentersPermissions = permissions(CostCentersPermissionsEnum, {
  featureId: 'CostCenters',
});
